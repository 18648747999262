import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


function IndexPage({  pathContext: { locale } } ) {
  return (
      <Layout>
        <SEO
          title="Closer Office"
          image="https://s3.eu-west-3.amazonaws.com/closerdev.com/closer-office/static+/hero.png"
          description="Closer Office makes you life easier when it comes to track your clients order. We keep you closer to your clients by creating a real time communication about the status of their demand."
          keywords={[
            `startup`,
            `office`,
            `management`,
            `online management`,
            `small business`,
            `orders organization`,
            `sells reports`,
            `business analysis`,
            `clients metrics`,
            `digital marketing`,
            'CRM',
            'PaaS',
            'business grow',
            'business management',
          ]}
        />
      </Layout>
  );
}

export default IndexPage;
